import React from 'react';

import PrimaryButton from '../components/ui/PrimaryButton';

const ErrorFallback: React.FunctionComponent = () => {
  return (
    <main className="bg-cover bg-center h-[70vh] font-oswald">
      <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
        <p className="text-xl font-semibold text-gray-400 uppercase tracking-wide">
          ERROR 500
        </p>
        <h1 className="mt-2 text-7xl font-extrabold text-white tracking-tight sm:text-5xl">
          Oops.
        </h1>
        <p className="mt-2 text-2xl font-medium text-gray-400 ">
          Algo salió mal. Reporta el error a nuestro equipo
        </p>
        <div className="mt-6 flex justify-center w-full">
          <PrimaryButton link="/" classNames="max-w-md rounded-full py-3">
            VUELVE A INICIO
          </PrimaryButton>
        </div>
      </div>
    </main>
  );
};

export default ErrorFallback;
