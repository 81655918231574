import React from 'react';
import { Link } from 'react-router-dom';

const PrimaryButton: React.FunctionComponent<{
  link: string;
  scrollToTop?: boolean;
  classNames?: string;
  children: string;
}> = ({
  link,
  classNames = 'rounded-md px-10 py-3',
  scrollToTop = false,
  children,
}) => {
  return (
    <Link
      onClick={(): void => {
        if (scrollToTop) {
          window.scrollTo(0, 0);
        }
      }}
      to={link}
      className={`block w-full text-center font-oswald font-bold shadow to-primary from-secondary bg-gradient-to-r text-white hover:from-white hover:to-white hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-white transform transition-all duration-300 ease-in-out hover:scale-105 ${classNames}`}
    >
      {children}
    </Link>
  );
};

export default PrimaryButton;
