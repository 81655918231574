import React, { lazy, Suspense } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import BicyclePreloader from './components/ui/BicyclePreloader';
const Home = lazy(() => import('./pages/home/Home'));
const Checkout = lazy(() => import('./pages/Checkout'));
const NotFound = lazy(() => import('./pages/NotFound'));
const OrderSummary = lazy(() => import('./pages/OrderSummary'));
const DetailMountainBiking = lazy(() => import('./pages/DetailMountainBiking'));
const DetailTrailRunning = lazy(() => import('./pages/DetailTrailRunning'));
const TermsAndConditions = lazy(() => import('./pages/TermsConditions'));
const DataPrivacy = lazy(() => import('./pages/DataPrivacy'));

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Suspense fallback={<BicyclePreloader message="" />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/resumen-compra" element={<OrderSummary />} />
          <Route path="/mtb" element={<DetailMountainBiking />} />
          <Route path="/trail-running" element={<DetailTrailRunning />} />
          <Route
            path="/terminos-condiciones"
            element={<TermsAndConditions />}
          />
          <Route path="/tratamiento-datos" element={<DataPrivacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
